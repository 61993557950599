<template>
  <div class="container">
    <p class="title">《隐私声明》</p>
    <p><strong>强生（上海）医疗器材有限公司</strong>（以下简称“强生”或“我们”）致力于保护您的个人信息。我们希望通过本隐私声明向您说明我们如何通过我们控制和运营的E起爱缝合平台处理个人信息。</p><br/>

    <h3>一、个人信息的收集</h3>
    <p>为了帮助您完成注册，保证您能正常使用本平台的相关功能，向您提供学术资讯，我们会收集并以适当方式处理您的以下个人信息：姓名、医院、职称、科室、手机号、邮箱。</p>
    <p>另外，为了了解您对本平台所提供内容和服务的反馈，改进和提高内容和服务质量，并进行数据分析，我们会收集并以适当方式处理您的行为数据。</p><br/>

    <h3>二、个人信息安全</h3>
    <p>我们承诺使用合理的组织、技术和管理措施来保护您的个人信息；防止未经授权或意外访问、销毁、修改、封锁、复制、散布及其他未经授权的处理。</p><br/>

    <h3>三、个人信息的存储与删除</h3>
    <p>我们将基于法律法规规定或限于实现本隐私声明目的所必要的最短期限内保存您的个人信息。</p>
    <p>在必要的保存期限届满后，除非法律、行政法规另有规定或您同意继续保留信息，我们将删除或匿名化处理您的个人信息。删除或匿名化难以实现的，我们将停止除存储和采取必要的安全保护措施之外的处理。</p><br/>

    <h3>四、个人信息的委托处理与共享</h3>
    <p>为满足本隐私声明的目的，我们可能委托第三方供应商来处理您的个人信息，我们会根据法律法规的要求，与第三方供应商严格根据本隐私声明的内容，约定委托处理的目的、期限、处理方式、个人信息范围、保护措施以及双方的权利和义务等，并对第三方供应商的个人信息处理活动进行监督。</p><br/>

    <h3>五、联系我们及行使权利</h3>  
    <p>如您对本隐私声明存在疑问，或希望行使您作为个人信息主体依法享有的权利，包括查阅、复制、转移、更正、补充、删除、限制处理个人信息以及注销账号、撤回同意，您可以通过发送邮件至Chinaprivacy@its.jnj.com联系我们，或通过以下链接提交您关于个人信息主体权利的请求：<a href="https://privacyportal.onetrust.com/webform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/3034f16e-7700-486e-98c2-b2cbb05f4cd3?ApplicationName=E起爱缝合_WeChat">https://privacyportal.onetrust.com/webform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/3034f16e-7700-486e-98c2-b2cbb05f4cd3?ApplicationName=E起爱缝合_WeChat</a>。如无特殊情况，我们会在验证您的身份后的30天内回复您的请求。</p><br/>

    <p style="text-align: right;">【更新日期：2024年12月】</p><br/><br/>

    
    <p class="title">附录：个人信息收集清单及第三方信息共享清单</p><br/>

    <p class="subtitle">一、个人信息收集清单</p><br/>
    <div class="table1">
      <div class="tr">
        <div class="td td1">使用场景</div>
        <div class="td td2">个人信息字段</div>
        <div class="td td3">使用目的</div>
      </div>
      <div class="tr">
        <div class="td td1">用户注册</div>
        <div class="td td2">姓名、医院、职称、科室、手机号、邮箱</div>
        <div class="td td3">帮助用户注册，保证用户能正常使用“E起爱缝合”平台的相关功能，向用户提供学术资讯</div>
      </div>
      <div class="tr">
        <div class="td td1">用户浏览数据分析</div>
        <div class="td td2">行为数据</div>
        <div class="td td3">了解用户对本平台所提供内容和服务的反馈，改进和提高内容和服务质量，并进行数据分析</div>
      </div>
    </div><br/>

    <p class="subtitle">二、第三方信息共享清单</p>
    <div class="table2">
      <div class="tr">
        <div class="td td1">使用场景</div>
        <div class="td td2">使用目的</div>
        <div class="td td3">共享个人信息字段</div>
        <div class="td td4">共享方式</div>
        <div class="td td5">接收个人信息的第三方主体类型</div>
      </div>
      <div class="tr">
        <div class="td td1">隐私声明所述场景</div>
        <div class="td td2">隐私声明所述目的</div>
        <div class="td td3">隐私声明所列举的个人信息字段</div>
        <div class="td td4">内部数据共享</div>
        <div class="td td5">强生关联方</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  font-size: 12px;
  padding: 12px;
  box-sizing: border-box;
  background-color: #F8F8F8;
  padding-bottom: 50px;
  .title {
    font-size: 15px;
    text-align: center;
    font-weight: bold;
  }
  .subtitle {
    font-size: 14px;
    font-weight: bold;
  }
  p {
    line-height: 2;
  }
  .ul {
    list-style-type: disc;
    margin-left: 30px;
    li {
      line-height: 2;
    }
  }
  .child_ul {
    list-style-type: circle;
    margin-left: 14px;
    li {
      line-height: 2;
    }
  }
  a {
    color: #1989fa;
  }
  .table1 {
    border: 1px solid #000000;
    .tr {
      display: flex;
      .td { 
        padding: 8px 5px;
      }
      .td1 {
        width: 20%;
        border-right: 1px solid #000000;
      }
      .td2 {
        width: 40%;
        border-right: 1px solid #000000;
      }
      .td3 {
        width: 40%;
      }
    }
    .tr:not(:last-of-type) {
      border-bottom: 1px solid #000000;
    }
    .tr:first-of-type {
      background-color: #dddddd;
    }
  }
  .table2 {
    border: 1px solid #000000;
    .tr {
      display: flex;
      .td { 
        padding: 8px 5px;
      }
      .td1 {
        width: 15%;
        border-right: 1px solid #000000;
      }
      .td2 {
        width: 20%;
        border-right: 1px solid #000000;
      }
      .td3 {
        width: 20%;
        border-right: 1px solid #000000;
      }
      .td4 {
        width: 15%;
        border-right: 1px solid #000000;
      }
      .td5 {
        width: 30%;
      }
    }
    .tr:not(:last-of-type) {
      border-bottom: 1px solid #000000;
    }
    .tr:first-of-type {
      background-color: #dddddd;
    }
  }
}
</style>