<template>
  <div class="container">
    <div class="countBox">
      <div class="countItem">
        <p class="count">{{ FansUserList.length }}</p>
        <div class="countTitle">
          <img src="@/assets/images/user.png" alt="" />
          <span>关注公众号</span>
        </div>
      </div>
      <div class="countItem">
        <p class="count">{{ UserList.length }}</p>
        <div class="countTitle">
          <img src="@/assets/images/user.png" alt="" />
          <span>注册账号</span>
        </div>
      </div>
    </div>
    <div class="screenTime">
      <span>筛选时间：</span>
      <div class="inputBox">
        <input type="text" v-model="startTime" readonly @click="showStart = true" />
      </div>
      <span style="margin: 0 8px; color: #CCCCCC;">—</span>
      <div class="inputBox">
        <input type="text" v-model="endTime" readonly @click="showEnd = true" />
      </div>
    </div>
    <van-popup v-model="showStart" round position="bottom" :style="{ height: '50%' }">
      <van-picker
        title="时间"
        show-toolbar
        :columns="time"
        @confirm="onStartConfirm"
        @cancel="onStartCancel"
      />
    </van-popup>
    <van-popup v-model="showEnd" round position="bottom" :style="{ height: '50%' }">
      <van-picker
        title="时间"
        show-toolbar
        :columns="time"
        @confirm="onEndConfirm"
        @cancel="onEndCancel"
      />
    </van-popup>
    <div class="content">
      <div class="record">
        <p class="recordTitle">邀请记录</p>
        <div class="classifyBox">
          <span class="classifyItem" :class="{'active': activeIndex == 0}" @click="changeRecord(0)">仅关注</span>
          <span class="classifyItem" :class="{'active': activeIndex == 1}" @click="changeRecord(1)">已注册</span>
        </div>
        <div class="listBox" v-show="activeIndex == 0">
          <div class="columnName">
            <span>被邀请人</span>
            <span>关注时间</span>
          </div>
          <div class="tip">已关注但未访问过微站的用户，无法纳入关注统计（微信不提供相关数据）</div>
          <div class="list">
            <div v-for="(item, index) in FansUserList" :key="index">
              <div class="listItem" v-if="item.Headimgurl && item.NickName">
                <div class="userInfo">
                  <!-- <img class="headImgUrl" v-if="item.Headimgurl" :src="item.Headimgurl" alt="" /> -->
                  <img class="headImgUrl" src="@/assets/images/headSculpture1.png" alt="" />
                  <span class="userName">{{ item.NickName }}</span>
                </div>
                <span class="time">{{dateFilter(item.CreateTime)}}</span>
              </div>
            </div>
            <p class="tips">暂时没有更多了~</p>
          </div>
        </div>
        <div class="listBox" v-show="activeIndex == 1">
          <div class="list list1" >
            <div class="listItem1" v-for="(item, index) in UserList" :key="index">
              <div class="itemInfo">
                <div class="userInfo">
                  <!-- <img class="headImgUrl" :src="item.Headimgurl" alt="" /> -->
                  <img class="headImgUrl" src="@/assets/images/headSculpture1.png" alt="" />
                  <span class="userName">{{ item.NickName }}</span>
                  <span style="margin-left: 10px;">{{item.SubjectName}}</span>
                  <span style="margin-left: 10px;">{{item.PositionalName}}</span>
                </div>
                <span class="time">{{dateFilter(item.CreateTime)}}</span>
              </div>
              <div class="hospitalName">
                <span>{{item.HospitalTitle}}</span>
              </div>
            </div>
            <p class="tips">暂时没有更多了~</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'MyInvitation',
  data() {
    return {
      activeIndex: 0,
      FansUserList: [],
      UserList: [],
      year: '',
      month: '',
      startTime: '',
      endTime: '',
      showStart: false,
      showEnd: false,
      time: [
        {
          values: ['2022年', '2023年', '2024年', '2025年', '2026年','2027年', '2028年', '2029年', '2030年', '2031年'],
          defaultIndex: 0,
        },
        {
          values: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          defaultIndex: 0,
        },
      ],
    }
  },
  created() {
    let date = new Date();
    this.year = date.getFullYear();
    this.month = date.getMonth() + 1;

    this.startTime = this.year + '-' + this.month;
    this.endTime = this.year + '-' + this.month;

    this.time[0].values.forEach((item, index) => {
      if(item.substr(0, item.length - 1) == this.year) {
        this.time[0].defaultIndex = index;
      }
    });

    this.time[1].values.forEach((item, index) => {
      if(item.substr(0, item.length - 1) == this.month) {
        this.time[1].defaultIndex = index;
      }
    });

    this.getFansUser();
    this.getSaleDoc();
  },
  computed: {
    ...mapState(["userInfo"]),
    dateFilter() {
      return (time) => {
        let date = time.split("T");
        return date[0] + ' ' + date[1].slice(0,date[1].lastIndexOf(':'));
      }
    }
  },
  watch: {
    startTime() {
      this.getFansUser();
      this.getSaleDoc();
    },
    endTime() {
      this.getFansUser();
      this.getSaleDoc();
    }
  },
  methods: {
    onStartConfirm(value) {
      let startTime = value[0].substr(0, value[0].length - 1) + '-' + value[1].substr(0, value[1].length - 1)
      this.startTime = startTime;
      this.showStart = false;
    },
    onStartCancel() {
      this.showStart = false;
    },
    onEndConfirm(value) {
      let endTime = value[0].substr(0, value[0].length - 1) + '-' + value[1].substr(0, value[1].length - 1)
      this.endTime = endTime;
      this.showEnd = false;
    },
    onEndCancel() {
      this.showEnd = false;
    },
    changeRecord(index) {
      this.activeIndex = index;
    },
    getFansUser() {
      this.$axios.post('/Api/Api/web/GetWxUser/GetFansUser', {
        CustomId: this.userInfo.Id,
        StartTime: this.startTime,
        EndTime: this.endTime
      }).then(res => {
        if (res.RetCode == '10000') {
          if (res.Data) {
            this.FansUserList = res.Data;
          }
        } else {
          this.$toast(res.RetMsg);
        }
      })
    },
    getSaleDoc() {
      this.$axios.post('/Api/Api/web/GetWxUser/GetSaleDoc', {
        CustomId: this.userInfo.Id,
        StartTime: this.startTime,
        EndTime: this.endTime
      }).then(res => {
        if (res.RetCode == '10000') {
          if (res.Data) {
            this.UserList = res.Data;
          }
        } else {
          this.$toast(res.RetMsg);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f8f8f8;
  padding: 15px;
  box-sizing: border-box;
}
.countBox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 90px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 12px #ebedf0;
  margin-bottom: 15px;
  .countItem {
    flex: 1;
  }
  .count {
    font-size: 18px;
    font-weight: bolder;
    color: #CA0018;
    text-align: center;
    padding: 4px 0;
  }
  .countTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 18px;
      margin-right: 5px;
    }
    span {
      font-size: 14px;
      color: #333333;
    }
  }
}
.screenTime {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 12px #ebedf0;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
  font-size: 14px;
  color: #333333;
  .inputBox {
    width: 100px;
    height: 22px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    overflow: hidden;
    input {
      width: 100%;
      height: 100%;
      border: none;
      box-sizing: border-box;
      padding: 0 6px;
      text-align: center;
    }
  }
}
.content {
  flex: 1;
  width: 100%;
  overflow: hidden;
  // height: 70%;
  // height: 0;
  .record {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 12px #ebedf0;
    overflow: hidden;
    .recordTitle {
      text-align: center;
      font-size: 14px;
      color: #333333;
      background-color: #FBE7E8;
      line-height: 30px;
    }
    .classifyBox {
      display: flex;
      height: 40px;
      font-size: 14px;
      color: rgba(69, 90, 100, 0.6);
      border-bottom: 1px solid #CCCCCC;
      .classifyItem {
        position: relative;
        flex: 1;
        line-height: 40px;
        text-align: center;
      }
      .active {
        color: #CA0018;
      }
      .active::after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: #CA0018;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .listBox {
      display: flex;
      flex: 1;
      // height: 0;
      flex-direction: column;
      overflow: hidden;
    }
    .columnName {
      display: flex;
      span {
        flex: 1;
        position: relative;
        display: inline-block;
        font-size: 14px;
        color: #333333;
        text-align: center;
        line-height: 42px;
      }
      span:first-child::after {
        content: "";
        width: 1px;
        height: 22px;
        background-color: #CCCCCC;
        position: absolute;
        top: 10px;
        right: 0;
      }
    }
    .tip {
      text-align: center;
      font-size: 9px;
      color: #CCCCCC;
      line-height: 24px;
    }
    .list {
      flex: 1;
      // height: 0;
      padding: 0 10px;
      box-sizing: border-box;
      overflow-y: scroll;
      .listItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        font-size: 12px;
        color: #333333;
        background-color: #FAFAFA;
        padding: 0 15px;
        border-radius: 40px;
        margin-bottom: 10px;
        .userInfo {
          display: flex;
          align-items: center;
        }
        .headImgUrl {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
      .listItem1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 56px;
        font-size: 12px;
        color: #333333;
        background-color: #FAFAFA;
        padding: 0 15px;
        border-radius: 56px;
        margin-bottom: 10px;
        .itemInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .userInfo {
          display: flex;
          align-items: center;
        }
        .headImgUrl {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 5px;
        }
        .hospitalName {
          padding-left: 25px;
        }
      }
    }
    .list1 {
      margin-top: 10px;
    }
  }
}
.tips {
  font-size: 12px;
  color: rgba(69, 90, 100, 0.6);
  text-align: center;
  margin-bottom: 10px;
}
</style>