<template>
  <div class="container">
    <div class="top">
      <img src="@/assets/images/bg@2x.png" alt="" />
      <div class="topmain">
        <div class="touxiang">
          <!-- <img :src="this.list.Headimgurl" alt="" /> -->
          <img v-if="list.type == 1" src="@/assets/images/headSculpture1.png" alt="" />
          <img v-else src="@/assets/images/headSculpture2.png" alt="" />
        </div>
        <div class="docName">{{ this.list.UserName }}</div>
        <div class="doczc">{{ this.list.PositionalName }}</div>
        <div class="docinfo" v-if="list.type == 1">
          <div class="docHos">
            <img class="docicon" src="@/assets/images/dizhi@2x.png" alt="" />{{
              this.list.HospitalTitle
            }}
          </div>
          <div class="dockeshi">
            <img
              class="docicon"
              src="@/assets/images/keshi@2x.png"
              alt=""
            /><span style="vertical-align: middle">{{
              this.list.SubjectName
            }}</span
            ><span class="shu" style="vertical-align: middle">|</span>
            <div class="keshi">{{ this.SonSub }}</div>
          </div>
        </div>

        <div class="docinfo" v-if="list.type == 2">
          <div class="docHos">
            <img class="docicon" src="@/assets/images/dizhi@2x.png" alt="" />
            {{ this.list.Email }}
          </div>
          <!-- <div class="dockeshi">
            <img
              class="docicon"
              src="@/assets/images/keshi@2x.png"
              alt=""
            /><span style="vertical-align: middle;">{{ this.list.SubjectName }}</span
            ><span class="shu" style="vertical-align: middle;">|</span>
            <div class="keshi">{{ this.SonSub }}</div>
          </div> -->
        </div>
      </div>

      <div class="tabbtn">
        <div class="btn1" @click="toMyFavorite">
          <img src="@/assets/images/mydz@2x.png" alt="" />
          我的点赞
        </div>
        <div class="btn1" @click="toMyCollection">
          <img src="@/assets/images/mysc@2x.png" alt="" />
          我的收藏
        </div>
        <div class="btn1" @click="toMyMeeting">
          <img src="@/assets/images/myhy@2x.png" alt="" />
          我的会议
        </div>
        <div class="btn1" @click="toBrowseRecords">
          <img src="@/assets/images/myjl@2x.png" alt="" />
          浏览记录
        </div>
      </div>

      <div class="ewm" @click="$router.push('/myQRcode')" v-if="list.type == 2">
        邀请码
        <img src="@/assets/images/pulldown_sign_icon@2x.png" alt="" />
      </div>

      <div class="ewm" @click="$router.push('/myInvitation')" v-if="list.type == 2">
        我的邀请
        <img src="@/assets/images/pulldown_sign_icon@2x.png" alt="" />
      </div>

      <div class="sytk" @click="$router.push('/termsOfUse')">
        使用条款
        <img src="@/assets/images/pulldown_sign_icon@2x.png" alt="" />
      </div>

      <div class="yszc" @click="$router.push('/privacyPolicy')">
        隐私政策
        <img src="@/assets/images/pulldown_sign_icon@2x.png" alt="" />
      </div>
      <a href="javascript:;" class="logout" @click="logout()">注销登录</a>
      <navbar />
    </div>
  </div>
</template>
<script>
import mixin from "../../common/mixin/mixins";
import navbar from "../../components/navbar.vue";
import { mapState } from "vuex";
import { Dialog } from "vant";
export default {
  name: "Home",
  data() {
    return {
      activeIndex: 1,
      list: [],
      loading: false,
      finished: false,
      error: false,
      SonSub: "",
    };
  },
  mixins: [mixin],
  components: {
    navbar,
  },
  watch: {},
  computed: {
    ...mapState(["Openid"]),
  },
  created() {
    this.onLoad();
    this.$store.commit("changeNavbarIndex", 2);
  },
  activated(){
    this.$store.commit('changeNavbarIndex', 2);
  },
  mounted() {},
  methods: {
    onLoad() {
      // alert(window.history.length);
      let that = this;
      that.loading = true;
      that.$axios
        .post("/Api/Api/Web/GetWxUser/GetCustmAll?OpenId=" + that.Openid)
        .then((res) => {
          that.list = res.Data;
          for (var i = 0; i < Object.keys(that.list.SonSubList).length; i++) {
            if (i == Object.keys(that.list.SonSubList).length - 1) {
              that.SonSub += that.list.SonSubList[i].Name;
            } else {
              that.SonSub += that.list.SonSubList[i].Name + "、";
            }
          }
          that.loading = false;
        });
      this.loading = false;
    },
    toMyFavorite() {
      this.BuriedPoint(1, "我的点赞", "", "/myFavorite");
      this.$router.push("/myFavorite");
    },
    toMyCollection() {
      this.BuriedPoint(1, "我的收藏", "", "/myCollection");
      this.$router.push("/myCollection");
    },
    toMyMeeting() {
      this.BuriedPoint(1, "我的会议", "", "/myMeeting");
      this.$router.push("/myMeeting");
    },
    toBrowseRecords() {
      this.BuriedPoint(1, "浏览记录", "", "/browseRecords");
      this.$router.push("/browseRecords");
    },
    logout() {
      let that = this;
      that.$axios
        .post("/Api/Api/Web/GetWxUser/LogOut?CustomerID=" + that.userInfo.Id)
        .then((res) => {
          Dialog.alert({
            message: "注销成功！",
          }).then(() => {
            if (localStorage.getItem("Token")) {
              localStorage.removeItem("Token");
            }
            this.$router.replace('/entrance');
          });
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  min-height: 100%;
  background: #f8f8f8;
  padding-bottom: 60px;
  box-sizing: border-box;
}

.top {
  position: relative;
}
.top > img {
  width: 100%;
}

.topmain {
  width: 74%;
  position: absolute;
  background: #fff;
  top: 80px;
  left: 50%;
  height: 8em;
  transform: translateX(-50%);
  padding: 4em 2em;
  padding-bottom: 2.5em;
  border-radius: 20px;
  background-image: url("../../assets/images/mpbg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.docName {
  text-align: center;
  font-size: 21px;
  margin-bottom: 0.3em;
  // color: #666;
}

.doczc {
  text-align: center;
  color: #888;
  font-size: 12px;
}

.touxiang {
  width: 22%;
  overflow: hidden;
  border-radius: 50em;
  position: absolute;
  top: -26px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
}

.touxiang > img {
  width: 100%;
  display: block;
}

.docinfo {
  position: absolute;
  width: 62%;
  left: 50%;
  transform: translateX(-50%);
}

.docHos {
  margin-top: 1em;
  font-size: 15px;
  color: #787878;
  position: relative;
}

.dockeshi {
  margin-top: 1em;
  font-size: 15px;
  color: #787878;
  position: relative;
}

.shu {
  margin: 0 0.6em;
}

.docicon {
  position: absolute;
  left: -2em;
  width: 1.5em;
}

.tabbtn {
  margin: auto;
  margin-top: 5em;
  text-align: center;
  margin-bottom: 1em;
  width: 90%;
  background: #fff;
  border-radius: 1em;
  padding: 2em 0;
}

.btn1 {
  display: inline-block;
  width: 23%;
  text-align: center;
  font-size: 13px;
  color: #666;
}

.btn1 > img {
  width: 3.5em;
  display: block;
  text-align: center;
  margin: auto;
  margin-bottom: 0.5em;
}

.ewm {
  margin: 0em auto 1em;
  padding: 0.8em 1em;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  border-radius: 0.5em;
  background: #fff;
  position: relative;
  width: 81%;
}

.ewm > img {
  width: 1em;
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}

.sytk {
  margin: 0em auto;
  padding: 0.8em 1em;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  border-radius: 0.5em;
  background: #fff;
  position: relative;
  width: 81%;
}

.sytk > img {
  width: 1em;
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}

.yszc {
  margin: 1em auto;
  padding: 0.8em 1em;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  border-radius: 0.5em;
  background: #fff;
  position: relative;
  width: 81%;
}

.yszc > img {
  width: 1em;
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}

.keshi {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 108px;
  vertical-align: middle;
}

.logout {
  width: 100%;
  text-align: center;
  display: block;
  color: #5989fd;
  margin-bottom: 20px;
}
</style>
